<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
   
  },
  mounted(){
    // console.log(process.env.VUE_APP_BASE_URL);
  },
  data() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
*{
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar { // 整个滚动条
    width: 10px;  // y轴滚动条宽度
    height: 10px;  // x轴滚动条宽度
    border-radius: 10px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {// 滚动条上的滚动滑块.
    border-radius: 10px;
    // color: aqua;
    -webkit-box-shadow: inset 0 0 8px #478db899;
    border-radius: 10px;
}
::-webkit-scrollbar-track {// 滚动条轨道.
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgba(16, 110, 85, 0.3);
    background-color: #F5F5F5;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 0px;
  background-color: #42b983;
  width: 100%;
  min-width: 1260px;
  min-height:100vh;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
