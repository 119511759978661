import Vue from 'vue'
import Router from 'vue-router'
// import IndexView from '../views/IndexView.vue'

Vue.use(Router)

export default new Router({
  routes: [
    { 
      path: "/",
      redirect: "/index" 
    },
    // {
    //   path: '/index',
    //   name: 'home',
    //   component: IndexView
    // },
    {
      path: '/index',
      name: 'home',
      component: resolve => require(['../views/IndexView.vue'],resolve),
      // meta: { 
      //   requireAuth: true // 配置此条，进入页面前判断是否需要登陆 
      // },
      children:[
        {
          path: '',
          name: 'HomePage',
          component: resolve => require(['../views/HomePage/HomePage.vue'],resolve),
        },
        {
          path: 'product/chatgpt',
          name: 'chatGPTPage',
          component: resolve => require(['../views/ProductPages/chatGPTPage.vue'],resolve),
        },
        {
          path: 'product/medicineLibrary',
          name: 'YpylkuPage',
          component: resolve => require(['../views/ProductPages/YpylkuPage.vue'],resolve),
        },
        {
          path: 'product/diseaseSymptomLibrary',
          name: 'JbzzkuPage',
          component: resolve => require(['../views/ProductPages/JbzzkuPage.vue'],resolve),
        },
        {
          path: 'product/foodLibrary',
          name: 'foodkuPage',
          component: resolve => require(['../views/ProductPages/foodkuPage.vue'],resolve),
        },
        {
          path: 'product/recipeLibrary',
          name: 'recipekuPage',
          component: resolve => require(['../views/ProductPages/recipekuPage.vue'],resolve),
        },
        {
          path: 'product/kinematicsLibrary',
          name: 'kinematicskuPage',
          component: resolve => require(['../views/ProductPages/kinematicskuPage.vue'],resolve),
        },
        {
          path: 'product/clinicalLibrary',
          name: 'clinicalkuPage',
          component: resolve => require(['../views/ProductPages/clinicalkuPage.vue'],resolve),
        },
        {
          path: 'product/docuguideLibrary',
          name: 'docuguidePage',
          component: resolve => require(['../views/ProductPages/docuguidePage.vue'],resolve),
        },
        {
          path: 'product/interactionLibrary',
          name: 'interactionkuPage',
          component: resolve => require(['../views/ProductPages/interactionkuPage.vue'],resolve),
        },
        {
          path: 'product/tabooLibrary',
          name: 'tabookuPage',
          component: resolve => require(['../views/ProductPages/tabookuPage.vue'],resolve),
        },
        {
          path: 'product/antibacterialLibrary',
          name: 'antibacterialkuPage',
          component: resolve => require(['../views/ProductPages/antibacterialkuPage.vue'],resolve),
        },
        {
          path: 'application',
          name: 'AppliCation',
          component: resolve => require(['../views/AppliCation/AppliCation.vue'],resolve),
        },
        {
          path: 'APIdocument',
          name: 'DocumentCenter',
          component: resolve => require(['../views/DocumentCenter/DocumentCenter.vue'],resolve),
        },

      ]
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  ]
})